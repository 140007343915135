import {html, LitElement} from "lit";
import {customElement, state} from "lit/decorators.js";
import style from './country-switcher.scss';
import {Theme, Tile} from "../../themes/models";
import {ThemeObserver} from "../../themes/observer";
import {Fader} from "../fader/fader";
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Country} from "../../services/country/country";
import {getCustomerService, ICustomerService} from "../../services/customer/customer-service";
import {Customer} from "../../services/customer/customer";

export const NY_COUNTRY_SWITCHER_KEY = 'NY_COUNTRY_SWITCHER_CONFIRMATION_DIALOG';

@customElement('country-switcher')
export class CountrySwitcher extends LitElement {

  @state() countryService: ICountryService;
  @state() currentCountry: Country;
  @state() menuVisible: boolean = false;
  @state() showMenu: boolean = false;
  @state() currentTheme: Theme;
  @state() customer: Customer;

  constructor() {
    super();
    this.countryService = getCountryService();

    this.onCountrySelected = this.onCountrySelected.bind(this);
    this.onMenuOpened = this.onMenuOpened.bind(this);
    this.onRequestThemeChange = this.onRequestThemeChange.bind(this);
    window.addEventListener('ny-request-theme-change', this.onRequestThemeChange);
  }

  connectedCallback() {
    window.addEventListener('ny-menu-opened', this.onMenuOpened);
    super.connectedCallback();
    this.countryService.subscribeForCurrentCountry('NY-COUNTRY-SWITCHER', c => this.onCountrySubscription(c));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('ny-menu-opened', this.onMenuOpened);
    window.removeEventListener('ny-request-theme-change', this.onRequestThemeChange);
  }

  dispatchConfirmationPopupOpenEvent(newCountry: Country) {
    window.dispatchEvent(new CustomEvent('country-switcher-confirmation-dialog:open', {
      bubbles: true,
      detail: newCountry
    }));
  }

  onMenuOpened(event) {
    const menu = event.detail.menu;
    if (!(menu instanceof CountrySwitcher) && this.menuVisible) {
      this.closeMenu();
    }
    this.getScrollPositionForActiveCountry();
  }

  getScrollPositionForActiveCountry() {
    const countrySwitcher = document.getElementById('country-switcher');
    const activeElement = countrySwitcher.shadowRoot.getElementById('active-country');
    const wrapper = countrySwitcher.shadowRoot.getElementById('country-list-scroll-container');
    const count = activeElement.offsetTop - wrapper.scrollTop - 250;
    wrapper.scrollBy({top: count, left: 0, behavior: 'auto'});
    this.showMenu = true;
  }

  onRequestThemeChange(event: CustomEvent) {
    if (event.detail.caller instanceof ThemeObserver || event.detail.caller instanceof CountrySwitcher || event.detail.caller instanceof Fader) {
      this.currentTheme = event.detail.theme as Theme;

      if (event.detail.caller instanceof ThemeObserver) {
        if (event.detail.tile === Tile.LEFT) {
          this.currentTheme = Theme.DARK
        }
      }
    }
  }

  onCountrySubscription(c: Country) {
    this.currentCountry = c;
  }

  onCountrySelected(event) {
    const c = event.detail.country as Country;
    const countryCode = this.countryService.getCountryCodeFromLocation();
    if (c.ny_iso.toLowerCase() !== countryCode.toLowerCase()) {
      this.dispatchConfirmationPopupOpenEvent(c);
    }
    this.closeMenu();
  }

  async onCountryClick() {
    if (!this.menuVisible) {
      window.dispatchEvent(new CustomEvent("ny-menu-opened", {
        detail: {
          menu: this as CountrySwitcher
        }
      }));
    }
    this.menuVisible = !this.menuVisible;
    this.toggleScroll();
    if (this.menuVisible) {
      window.addEventListener('ny-country-selected', this.onCountrySelected)
    } else {
      window.removeEventListener('ny-country-selected', this.onCountrySelected)
    }
  }

  closeMenu() {
    this.menuVisible = false;
    this.showMenu = false;
    this.toggleScroll();
  }

  render() {
    return html`
      <style>${style}</style>
      ${this.menuVisible ? html`
        <div class="closable-overlay" @click="${this.closeMenu}"></div>` : null}
      <div class="country-switcher ${this.currentTheme}" @click="${this.onCountryClick}">
        ${this.menuVisible ? html`<img src="/img/icons/times-circle.svg"
                                       alt="Country Switcher Close"/>` : this.currentCountry ? this.currentCountry?.iso_3166 : 'DE'}
      </div>
      <div class="country-menu ${this.menuVisible && this.showMenu ? 'active' : ''}">
        <country-list currentCountry="${this.currentCountry?.iso_3166}"></country-list>
      </div>

    `
  }

  toggleScroll() {
    if (this.menuVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'initial';
    }
  }


}
