import styles from './country-picker.scss';
import {ItemSelector} from "./item-selector";
import conf from '../../config'
import {getCountryService, ICountryService} from "../../services/country/country-service";
import {Country} from "../../services/country/country";

export class CountryPicker extends HTMLElement {
  private imageKey: string;
  private mobileImageKey: string;
  private cs: ICountryService;
  private itemSelector: ItemSelector;
  private selectedCountry: Country;
  private currentCountry: Country;
  private active: boolean;
  private countries: Country[];

  constructor() {
    super();
    this.cs = getCountryService();

    this.addEventListener('click', this.onClick.bind(this));
    window.addEventListener('ny-country-selected', this.onCountryClick.bind(this));
    window.addEventListener('countrySwitcher:toggle', this.onToggleEvent.bind(this))

    if (this.getUrlParam('disableCountrySwitcher')) {
      this.render = () => {return;};
      this.unrender = () => {return;};
    }
  }

  onToggleEvent() {
    this.active ? this.unrender() : this.render();
  }

  onClick(event) {
    if (event.target instanceof HTMLAnchorElement) {
      return;
    }

    if (event.target instanceof ItemSelector) {
      this.itemSelector.isActive() ? this.itemSelector.setActive(false) : this.itemSelector.setActive(true);
    } else {
      if (this.itemSelector.isActive()) {
        this.itemSelector.setActive(false)
      }
    }
    this.toggleElements();
  }

  toggleElements() {
    this.querySelectorAll('.country-picker .toggleable')?.forEach(elem => {
      this.itemSelector.isActive() ? elem.classList.add('hide') : elem.classList.remove('hide');
    })
    this.querySelector('.country-picker')?.classList.toggle('active');
  }

  onCountrySubscription(country: Country) {
    const countryCode = this.cs.getCountryCodeFromLocation();
    if (country) {
      this.currentCountry = country;
    } else {
      if (countryCode === conf.defaultLang) {
        this.render();
      } else {
        const byCountryCode = this.countries.find(c => c.ny_iso.toLowerCase() === countryCode.toLowerCase());
        this.cs.setCurrentCountry(byCountryCode);
      }
    }
  }

  onCountryClick(event) {
    this.selectedCountry = event.detail.country as Country;
    this.itemSelector?.setActive(false);
  }

  onSaveButtonClick() {
    if (this.selectedCountry) {
      this.cs.setCurrentCountry(this.selectedCountry);
      this.cs.routeToCountry(this.selectedCountry.ny_iso.toLowerCase())
    }
    this.unrender();
  }

  render() {
    const bodyElement = document.querySelector('#fashion') as HTMLElement;
    bodyElement.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    this.active = true;
    this.imageKey = this.getAttribute('imagekey') || this.imageKey;
    this.mobileImageKey = this.getAttribute('mobileimagekey') || this.mobileImageKey;
    this.innerHTML = `
      <style>${styles}</style>
      <style>
        .background-image {
            background: url(${this.imageKey}) no-repeat; background-size: cover;
        }

        @media screen and (max-width: 1024px) {
          .background-image {
            background: url(${this.mobileImageKey}) no-repeat; background-size: cover;
          }
        }

      </style>
      <div class="background-image"></div>
      <div class="country-picker-container">
        <div class="overlay">
          <div class="country-picker">
          <div class="image-container toggleable">
            <svg>
              <use xlink:href="/img/icons/sprites.svg#ny-logo"/>
            </svg>
          </div>
          <div class="item-selector-container">
            <item-selector>
              <country-list></country-list>
            </item-selector>
          </div>
          <div class="button-container toggleable">
            <button>GO</button>
          </div>
          <div class="legal-container toggleable">
            <a href="/legal/imprint?disableCountrySwitcher=true">Impressum</a>
            <a href="/legal/privacy?disableCountrySwitcher=true">Datenschutz</a>
          </div>
        </div>
        </div>
      </div>`
    this.itemSelector = this.querySelector('.country-picker item-selector') as ItemSelector;
    this.querySelector('.country-picker .button-container button').addEventListener('click', this.onSaveButtonClick.bind(this))
  }

  unrender() {
    const bodyElement = document.querySelector('#fashion') as HTMLElement;
    bodyElement.style.overflow = 'initial';
    document.documentElement.style.overflow = 'initial';
    this.active = false;
    this.innerHTML = null;
  }

  getUrlParam(name, url?) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  async connectedCallback() {
    this.countries = await this.cs.getCountries();
    this.selectedCountry = this.currentCountry || this.countries[0];
    const NY_COUNTRY_PICKER = 'NY_COUNTRY_PICKER'
    this.cs.subscribeForCurrentCountry(NY_COUNTRY_PICKER, this.onCountrySubscription.bind(this))
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.onClick.bind(this));
    this.querySelector('.country-picker .button-container button').addEventListener('click', this.onSaveButtonClick.bind(this))
    window.removeEventListener('ny-country-selected', this.onCountryClick.bind(this));
    window.removeEventListener('countrySwitcher:toggle', this.onToggleEvent.bind(this))
  }
}

window.customElements.define('country-picker', CountryPicker);
